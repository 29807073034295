<template>
  <div class="d-flex flex-column">
    <span class="mr-1">Umsatz:</span>

    <div class="d-flex justify-content-center flex-xl-nowrap flex-lg-wrap text-muted">
      <b-input-group size="sm" class="flex-nowrap mb-xl-0 mb-lg-2" prepend="€">
        <b-form-input
          tabindex="9"
          class="mr-xl-2 mr-lg-0"
          size="sm"
          type="number"
          placeholder="MIN"
          :title="$t('COMMON.UMSATZMIN')"
          v-model.number="min"
          step="1000"
          :aria-label="$t('COMMON.MINIMUM')"
          @update="$emit('input', { min: parseFloat(min), max: parseFloat(max) })"
        ></b-form-input>
      </b-input-group>
      <b-input-group size="sm" class="flex-nowrap" prepend="€">
        <b-form-input
          tabindex="10"
          size="sm"
          type="number"
          placeholder="MAX"
          :title="$t('COMMON.UMSATZMAX')"
          v-model.number="max"
          step="1000"
          :aria-label="$t('COMMON.MAXIMUM')"
          @update="$emit('input', { min: parseFloat(min), max: parseFloat(max) })"
        ></b-form-input>
      </b-input-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object },
  },
  data() {
    return {
      min: null,
      max: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function (f) {
        // check if f exists. if it does check if min or max exists and if its not 0
        this.min = f ? (!f.min && f.min !== 0 ? null : f.min) : null;
        this.max = f ? (!f.max && f.max !== 0 ? null : f.max) : null;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
