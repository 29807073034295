<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center flex-lg-wrap text-muted">
      <b-input-group size="sm" title="Interessenten" prepend="Interessenten">
        <b-form-input
          :placeholder="placeholder"
          title="PAX"
          aria-label="PAX"
          class="mr-xl-2 mr-lg-0"
          size="sm"
          type="number"
          v-model.number="pax"
          @update="onUpdatePax"
        ></b-form-input>
        <b-input-group-prepend>
          <b-button
            title=" 1 Interessent"
            variant="outline-secondary"
            @click="
              pax = 1;
              onUpdatePax(1);
            "
            >1</b-button
          >
          <b-button
            title=" 2 Interessenten"
            variant="outline-secondary"
            @click="
              pax = 2;
              onUpdatePax(2);
            "
            >2</b-button
          >
        </b-input-group-prepend>
      </b-input-group>
      <b-input-group class="mt-2 ml-2">
        <b-form-checkbox v-model="isFreeSpaceSelected" @change="onUpdateFreeSpace" class=""
          >Minimum PAX mit Anzahl der Interessenten erreicht</b-form-checkbox
        >
      </b-input-group>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    placeholder: { type: String },
    value: { type: Object },
  },
  data() {
    return {
      pax: 1,
      isFreeSpaceSelected: true,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function (f) {
        this.pax = f ? parseInt(f.pax) : null;
        this.isFreeSpaceSelected = f ? f.isFreeSpaceSelected : null;
      },
    },
  },
  methods: {
    onUpdatePax($event) {
      this.$emit('input', { pax: parseInt($event), isFreeSpaceSelected: this.isFreeSpaceSelected });
    },
    onUpdateFreeSpace($event) {
      this.$emit('input', { pax: parseInt(this.pax || 1), isFreeSpaceSelected: $event });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.multiselect--disabled {
  background: none;
}
</style>
