<template>
  <div>
    <CustomMultiselect
      v-model="selected"
      @input="$emit('input', $event || [])"
      :options="parsedOptions"
      :loading="isLoading"
      :multiple="true"
      :close-on-select="true"
      :showLabels="false"
      :group-select="true"
      :title="placeholder"
      :placeholder="placeholder"
      ref="myMultiSelect"
      group-values="editStates"
      group-label="workflowStateLabel"
      track-by="state"
      label="text"
    >
      <span slot="noResult">Nichts gefunden.</span>
      <template slot="option" slot-scope="props">
        <div
          class="text-wrap font-weight-light ml-2"
          :class="props.option.$groupLabel ? 'font-weight-bold' : ''"
        >
          {{ props.option.text || props.option.$groupLabel }}
        </div>
      </template>
    </CustomMultiselect>
  </div>
</template>
<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
import { mapState } from 'vuex';
import { groupBy } from '@/core/common/helpers/utils';

export default {
  components: { CustomMultiselect },
  props: ['value', 'translationPrefix', 'placeholder'],
  mounted() {
    this.selected = this.value;
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    value(v) {
      this.selected = v === null ? [] : v;
    },
  },
  computed: {
    ...mapState({
      isLoading: state => state.stateConfig.isLoading,
      reiseterminStates: state => state.stateConfig.reiseterminStates,
    }),
    parsedOptions() {
      if (Array.isArray(this.reiseterminStates) && this.reiseterminStates.length > 0) {
        return this.orderStatesByWorkflowAndSubstates(
          'REISETERMINE.statesFilterNames.',
          this.reiseterminStates
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    orderStatesByWorkflowAndSubstates(translationPrefix, states) {
      let groupedByStateType = groupBy(states, 'stateType');
      let groupedBySubstates = groupBy(groupedByStateType.EditState, 'substateOf');
      return Object.entries(groupedBySubstates).reduce((acc, [key, value]) => {
        acc.push({
          workflowStateLabel: this.$t(translationPrefix + key),
          editStates: value.map(editState => ({
            ...editState,
            text: this.$t(translationPrefix + editState.state),
          })),
        });
        return acc;
      }, []);
    },
  },
};
</script>
