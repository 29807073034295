<template>
  <div>
    <b-form-radio-group
      class="w-100"
      v-model="selected"
      :options="options"
      buttons
      block
      stacked
      size="sm"
      button-variant="secondary"
      @input="$emit('input', $event)"
    ></b-form-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number },
  },
  data() {
    return {
      selected: 0,
      options: [
        { text: this.$t('FILTER.OHNEGESCHOBENETERMINE'), value: 0 },
        { text: this.$t('FILTER.NURGESCHOBENETERMINE'), value: 1 },
        { text: this.$t('FILTER.ALLETERMINE'), value: 2 },
      ],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function (v) {
        this.selected = v === null ? [] : v;
      },
    },
  },
};
</script>
